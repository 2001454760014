import React from 'react'
import styled from '@emotion/styled/macro'
import { Redirect } from 'react-router-dom'
import { ImageContent } from '../shared/styled'
import PropTypes from 'prop-types'
import ImageList from '../shared/image-list'
import GiftOfChoice from '../gift-of-choice'
import { graphql } from '@apollo/client/react/hoc'
import getAllCategories from '../../graphql/getAllCategories'
import { trackImpression } from '../../utils/analytics'
import { handleSessionError } from '../../utils/error-tracking'
import { flowRight as compose } from 'lodash'
import { Message } from '@paypalcorp/worldready-react'
import { HeadingText } from '@paypalcorp/pp-react'
import { Loader } from '../../components/loader'

const Title = styled(HeadingText)`
  font-size: 30px;
  color: #4a4a4a;
  text-align: center;
  margin: auto;
  padding-top: 36px;
  padding-bottom: 36px;
`

export class Categories extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      getAllCategories: PropTypes.array,
    }).isRequired,
  }

  componentDidMount() {
    trackImpression(
      'main:consumer:gifts:home:',
      'main:consumer:gifts:home:categories:::',
    )
  }

  render() {
    if (this.props.data.loading) {
      return <Loader py="10rem" />
    }
    if (this.props.data.error) {
      handleSessionError(this.props.data.error)
      return <Redirect to="/error" />
    }

    const categories = this.props.data.getAllCategories

    return (
      <div id="choose-a-category">
        <Title>
          <Message id="pages/categories.categories.header" />
        </Title>
        <div id="content-wrapper" style={{ textAlign: 'center' }}>
          <ImageContent>
            <ImageList items={categories} />
          </ImageContent>
        </div>
        <GiftOfChoice parentPage="categories" />
      </div>
    )
  }
}

const CategoriesWithData = compose(graphql(getAllCategories))(Categories)

export default CategoriesWithData
