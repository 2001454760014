import React, { Fragment } from 'react'
import stripHtmlComments from 'strip-html-comments'
import * as Styled from './text-link-toggle.styled'
import DomPurify from 'dompurify'

const TextLinkToggle = ({
  expandText,
  handleClick,
  handleKeyPress,
  linkTitle,
  htmlTextBody,
  titleStyle = {},
}) => {
  return (
    <Fragment>
      <Styled.LinkTitle
        tabIndex="0"
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        style={titleStyle}
      >
        {linkTitle}
        {expandText ? (
          <Styled.ChevronUpIcon size="xs" />
        ) : (
          <Styled.ChevronDownIcon size="xs" />
        )}
      </Styled.LinkTitle>

      {expandText && (
        <Styled.TextBody>
          <div
            dangerouslySetInnerHTML={{
              __html: DomPurify.sanitize(stripHtmlComments(htmlTextBody)),
            }}
          />
        </Styled.TextBody>
      )}
    </Fragment>
  )
}

export default TextLinkToggle
