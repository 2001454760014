import styled from '@emotion/styled/macro'
import { flexbox } from 'styled-system'
import { Container } from '../grid'
import { Box } from '../box'

export const CarouselSlider = styled(Box)`
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  ${flexbox}
  scroll-behavior: smooth;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`

export const Indicators = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Indicator = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;

  margin-right: 4px;
  transition: background-color 0.2s;

  background-color: ${(props) => (props.isActive ? 'black' : 'grey')};
`

export const Controls = styled(Container)`
  z-index: 10;
  top: 0;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .carousel-prev::before {
    transform: translateX(-2px);
  }
  .carousel-next::before {
    transform: translateX(1px);
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }
`

export const ScrollButton = styled.span`
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  pointer-events: all;
  transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;
  :hover {
    background-color: ${(props) => props.theme.colors.secondary.greylight};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`

export const Faders = styled(Container)`
  z-index: 5;
  top: 0;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ::before {
    margin-left: -1rem;
    content: '';
    height: calc(100% + 2rem);
    width: 1rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  ::after {
    margin-right: -1rem;
    content: '';
    height: calc(100% + 2rem);
    width: 1rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 5%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  ::before,
  ::after {
    margin-top: -1rem;
  }
`
