import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@paypalcorp/pp-react'
import { Text } from '../text'
import stripHtmlComments from 'strip-html-comments'
import { Message } from '@paypalcorp/worldready-react'
import DomPurify from 'dompurify'

import * as Styled from './Deal.styled'

const Deal = (props) => {
  const { offer, noIcon } = props
  const [showOfferTerms, setShowOfferTerms] = useState(false)

  const toggleOfferTerms = () => {
    setShowOfferTerms(!showOfferTerms)
  }

  const handleOfferTermsKeyPress = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      // Prevent the default action to stop scrolling when space is pressed
      event.preventDefault()
      toggleOfferTerms()
    }
  }

  return (
    <Styled.Deal>
      {noIcon ? null : <Styled.DealIcon name="offers-rewards" size="sm" />}
      <Styled.DealText>
        {offer.name ? (
          <Text as="div" textStyle="deal">
            {offer.name}
          </Text>
        ) : null}
        <Text as="div" textStyle="deal">
          <button
            onClick={toggleOfferTerms}
            onKeyPress={handleOfferTermsKeyPress}
          >
            <Message id="pages/deals.deals.offerTerms" />
            <Icon
              name={showOfferTerms ? 'chevron-up' : 'chevron-down'}
              size="xs"
              color="LinkMain"
            />
          </button>
        </Text>
        {showOfferTerms && (
          <Styled.TermsBody>
            <div
              dangerouslySetInnerHTML={{
                __html: DomPurify.sanitize(stripHtmlComments(offer.terms)),
              }}
            />
          </Styled.TermsBody>
        )}
      </Styled.DealText>
    </Styled.Deal>
  )
}

Deal.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    terms: PropTypes.string,
  }),
  noIcon: PropTypes.bool,
}

export default Deal
