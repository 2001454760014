import styled from '@emotion/styled/macro'
import { keyframes, css } from '@emotion/react/macro'
import { Box } from '../box'
import { Text } from '../text'

export const TitleWrapper = styled(Box)`
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  pointer-events: none;
  text-align: left;
`

const moveout = keyframes`
  0% {
    transform: translateX(0rem);
  }
  100% {
    transform: translateX(12rem);
  }
`

const movein = keyframes`
  0% {
    transform: translateX(-15rem);
  }
  100% {
    transform: translateX(0rem);
  }
`

export const AnimText = styled(Text)`
  margin: 0;
  padding: 0;
  margin-top: 80px;
  line-height: 1.1;
  ${(props) => {
    switch (props.animState) {
      case 'out':
        return css`
          animation: ${moveout} 0.3s cubic-bezier(1, 0, 1, 0.5);
        `
      case 'in':
        return css`
          animation: ${movein} 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.97);
        `
      default:
        return ''
    }
  }}
`

export const RefText = styled(Text)`
  opacity: 0;
  pointer-events: 0;
  max-height: 0;
  position: absolute;
`
