import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import { HeadingText } from '@paypalcorp/pp-react'
import { Text } from '../../../components/text'

const DivContainer = styled.div`
  margin: 0 10px 24px 10px;

  @media only screen and (max-width: 768px) {
    margin: 0 6px 24px 6px;
  }
`

const GiftCardDiv = styled.div`
  width: 160px;
`

const Img = styled.img`
  width: 160px;
  height: 101px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #ffffff;
`

const Name = styled(Text)`
  color: #212529;
  padding-bottom: 6px;
  text-align: center;
`

const Title = styled(HeadingText)`(
  padding-left: 12px;
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 300;
  color: #4a4a4a;
`

const DisplayDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const EmptyItem = styled.div`
  margin: 0 10px;
  width: 160px;

  @media only screen and (max-width: 768px) {
    margin: 0 6px;
  }
`

class GiftCardsList extends React.Component {
  static propTypes = {
    basepath: PropTypes.string,
    items: PropTypes.array.isRequired,
    title: PropTypes.string,
    history: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    centerAlign: PropTypes.string,
  }

  addCardClickTrackingProps = ({ name }) => {
    // only track card click if from /gifts/all
    const fromAllGiftCards = window.location.pathname.includes('/gifts/all')
    if (!fromAllGiftCards) {
      return {}
    }

    return {
      'data-pagename': 'main:consumer:gifts:home:',
      'data-pagename2': 'main:consumer:gifts:home:allgifts:::',
      'data-extra': JSON.stringify({
        pp_flow: encodeURIComponent(name),
      }),
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        {this.props.title && (
          <Title
            size="sm"
            style={this.props.centerAlign && { textAlign: 'center' }}
          >
            {this.props.title}
          </Title>
        )}
        <DisplayDiv>
          {this.props.items &&
            this.props.items
              .filter((i) => i)
              .map((item, idx) => {
                return (
                  <DivContainer key={item.id}>
                    <Link
                      tabIndex={idx + 1}
                      to={`${this.props.basepath || ''}${item.url_key}`}
                      data-linkname="Card"
                      {...this.addCardClickTrackingProps(item)}
                    >
                      <GiftCardDiv>
                        <Img src={item.img_url} alt={item.name} />
                        <Name as="h3" textStyle="cardTitle">
                          {item.name}
                        </Name>
                      </GiftCardDiv>
                    </Link>
                  </DivContainer>
                )
              })}
          <EmptyItem />
          <EmptyItem />
          <EmptyItem />
        </DisplayDiv>
      </div>
    )
  }
}

const GiftCardsListWithGraphql = compose(withApollo)(withRouter(GiftCardsList))
export default GiftCardsListWithGraphql
