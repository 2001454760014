import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { FlexBox } from '../box'
import { Text } from '../text'
import * as Styled from './TitleTicker.styled'

const TRANSITION_STATES = ['ready', 'out', 'in', 'static']
const MAX_TEXT_WIDTH = 550

const MOVE_OUT_DURATION = 300
const MOVE_IN_DURATION = 300

const TitleTicker = ({ staticText, textItems, delay, ...rest }) => {
  const [animState, setAnimState] = useState({
    itemIndex: 0,
    transitionIndex: 0,
  })

  const currentTimeout = useRef(false)
  const sizeRef = useRef()
  const staticRef = useRef()

  const numItems = textItems.length
  const { itemIndex, transitionIndex } = animState

  const doRotate = () => {
    const nextIndex = (itemIndex + 1) % numItems

    // Start "move out" animation
    setAnimState({
      itemIndex,
      transitionIndex: 1, // out
    })

    currentTimeout.current = setTimeout(() => {
      setAnimState({
        itemIndex: nextIndex,
        transitionIndex: 2, // in
      })

      currentTimeout.current = setTimeout(() => {
        setAnimState({
          itemIndex: nextIndex,
          transitionIndex: 3, // static
        })

        currentTimeout.current = setTimeout(() => {
          setAnimState({
            itemIndex: nextIndex,
            transitionIndex: 0, // ready
          })
        }, delay - (MOVE_OUT_DURATION + MOVE_IN_DURATION))
      }, MOVE_IN_DURATION)
    }, MOVE_OUT_DURATION)
  }

  useEffect(() => {
    if (transitionIndex === 0) doRotate()
  })

  useEffect(() => {
    return () => {
      if (currentTimeout.current) {
        clearTimeout(currentTimeout.current)
        currentTimeout.current = false
      }
    }
  }, [])

  if (!numItems) return null

  const longestText = textItems.sort((a, b) => b.length - a.length)[0]
  const textWidth = sizeRef.current
    ? Math.min(sizeRef.current.clientWidth, MAX_TEXT_WIDTH)
    : MAX_TEXT_WIDTH

  return (
    <Styled.TitleWrapper as="h1" className="ticker" {...rest}>
      <FlexBox flexDirection="row" justifyContent="center">
        <FlexBox
          flexDirection={{ _: 'column', sm: 'row' }}
          justifyContent="center"
          alignContent="end"
        >
          {staticText && (
            <Text as="span" ref={staticRef} textStyle="hero">
              {staticText}&nbsp;
            </Text>
          )}

          {sizeRef.current && (
            <Styled.AnimText
              as="span"
              animState={TRANSITION_STATES[transitionIndex]}
              textStyle="hero"
              width={`${textWidth}px`}
            >
              {textItems[itemIndex]}
            </Styled.AnimText>
          )}
        </FlexBox>
      </FlexBox>
      <Styled.RefText
        textStyle="hero"
        ref={sizeRef}
        maxWidth={`${MAX_TEXT_WIDTH}px`}
      >
        {longestText}
      </Styled.RefText>
    </Styled.TitleWrapper>
  )
}

TitleTicker.propTypes = {
  staticText: PropTypes.string,
  textItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  delay: PropTypes.number,
}

TitleTicker.defaultProps = {
  delay: 2000,
}

export default TitleTicker
