import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import InputFieldWithBtn from '../shared/input-field-with-btn'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import {
  CaptionText,
  Container,
  CriticalIcon,
  Radio,
  Row,
} from '@paypalcorp/pp-react'
import { MessageFormat, CurrencyFormat } from '@paypalcorp/worldready'
import {
  Message,
  ContextConsumer as WorldReadyContextConsumer,
} from '@paypalcorp/worldready-react'
import { Text } from '../../components/text'
import { FlexBox } from '../../components/box'
import { fractionDisplay } from '../../utils/fraction-display'
import _, { flowRight as compose } from 'lodash'

const RadioBtn = styled(Radio)`
  padding: 0;
  width: auto;
  margin-right: 24px;
`

const AmountLabel = styled(CaptionText)`
  font-size: 15px;
  line-height: 1.6 !important;
  color: #2c2e2f;

  &::before {
    font-size: 24px !important;
  }
`

const AmountField = styled.div`
  width: 100%;
  height: 48px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const BundleAmount = styled(FlexBox)`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary.greymedium};
`

const ErrorMessageContainer = styled.div`
  color: #d20000;
  fontsize: 13px;
  display: flex;
  align-content: center;
`

const ErrorMessageText = styled(CaptionText)`
  color: #d20000;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
`

export class AmountSection extends React.Component {
  static propTypes = {
    fixedAmounts: PropTypes.array,
    customAmountsWithSku: PropTypes.object,
    client: PropTypes.object,
    handleClickRadioBtn: PropTypes.func,
    onEnter: PropTypes.func,
    showApplyAmountError: PropTypes.bool,
    isBundle: PropTypes.bool,
    sku: PropTypes.string,
  }

  state = {
    showCustomAmountField: false,
    error: false,
  }

  componentDidMount() {
    const { fixedAmounts, customAmountsWithSku } = this.props
    if (!_.isEmpty(fixedAmounts)) {
      this.handleClickFixAmountRadioBtn(fixedAmounts[0].sku)
    }

    // If the custom amount is the only SKU on the product, select it by default
    const hasOnlyCustomAmountSku =
      _.isEmpty(fixedAmounts) && customAmountsWithSku !== null
    if (hasOnlyCustomAmountSku) {
      this.setState({ showCustomAmountField: true }) // eslint-disable-line react/no-did-mount-set-state
      this.props.client.writeQuery({
        query: gql`
          query getCachedData {
            sku @client
          }
        `,
        data: { sku: customAmountsWithSku.sku },
      })
    }
  }

  handleClickApply = (isValid) => {
    this.setState({ error: !isValid })
  }

  suppressErrorMsg = () => {
    this.setState({ error: false })
  }

  renderAmountInputField = (customAmounts) => {
    const cachedData = this.props.client.readQuery({
      query: gql`
        query getCachedData {
          customAmount @client {
            currency_code
            value
          }
        }
      `,
    })
    let enteredValue
    if (cachedData.customAmount.value > 0) {
      enteredValue = (cachedData.customAmount.value / 100).toString()
    }

    return (
      <WorldReadyContextConsumer>
        {(worldready) => {
          const stepPriceAmount = _.get(
            this.props,
            'customAmountsWithSku.customAmount.stepPriceAmount',
          )

          if (stepPriceAmount) {
            const currency = _.get(
              this.props,
              'customAmountsWithSku.customAmount.minAmount.currency_code',
              'USD',
            )

            this.stepAmount = new CurrencyFormat(worldready, {
              currency,
            }).format(stepPriceAmount / 100)
          }

          return (
            <div>
              <AmountField id="amount-field" data-testid="amount-field">
                <InputFieldWithBtn
                  id="enter-amount-field"
                  data-testid="amount-field"
                  customAmount={true}
                  customAmountsWithSku={this.props.customAmountsWithSku}
                  title={new MessageFormat(worldready, {
                    id: 'pages/product.product.amount',
                  }).format()}
                  placeholder={new MessageFormat(worldready, {
                    id: 'pages/product.product.amountPlaceHolder',
                  }).format({
                    minAmount: customAmounts.minAmount,
                    maxAmount: customAmounts.maxAmount,
                  })}
                  inputDefaultValue={enteredValue}
                  handleBtnClick={(value) => this.handleClickApply(value)}
                  suppressErrorMsg={this.suppressErrorMsg}
                  onEnter={this.props.onEnter}
                  amountError={
                    this.state.error || this.props.showApplyAmountError
                  }
                  shouldApplyOnBlur={true}
                />
              </AmountField>
              {this.state.error && (
                <ErrorMessageContainer id="invalid-input">
                  <CriticalIcon size="sm" />
                  <ErrorMessageText>
                    {this.stepAmount ? (
                      <Message
                        id="pages/product.product.amountError"
                        stepAmount={this.stepAmount}
                      />
                    ) : (
                      <Message id="pages/product.product.amountError" />
                    )}
                  </ErrorMessageText>
                </ErrorMessageContainer>
              )}
            </div>
          )
        }}
      </WorldReadyContextConsumer>
    )
  }

  handleClickFixAmountRadioBtn = (sku) => {
    this.setState({ showCustomAmountField: false })
    // clear checkmark and error msg if there was any
    this.setState({ error: false })

    this.props.handleClickRadioBtn(sku)
  }

  renderFixAmountRadioBtn = (displayAmounts, selectedSku) => {
    return displayAmounts.map((item, idx) => {
      const itemId = item.displayAmount.substring(1) // remove the "$"
      return (
        <RadioBtn
          size="xs"
          key={`amount-${itemId}-${idx}`}
          id={`amount-${itemId}-${idx}`}
          data-testid={`amount-${idx}`}
          hidden={this.props.isBundle}
          checked={item.sku === selectedSku}
          onChange={() => this.handleClickFixAmountRadioBtn(item.sku)}
          label={<AmountLabel>{item.displayAmount}</AmountLabel>}
        />
      )
    })
  }

  handleClickOther = (sku) => {
    this.setState({ showCustomAmountField: true })
    this.props.handleClickRadioBtn(sku, true)
  }

  getFixedDisplayAmounts = (fixedDenominations, worldready) => {
    // sort amount
    fixedDenominations.sort(function (a, b) {
      const aValue = parseFloat(a.denomination.value)
      const bValue = parseFloat(b.denomination.value)
      if (aValue < bValue) return -1
      if (aValue > bValue) return 1
      return 0
    })

    const formatedDenominations = fixedDenominations.map((item) => {
      const value = parseFloat(_.get(item, 'denomination.value', 'NaN'))
      const currency = _.get(item, 'denomination.currency', 'USD')

      const displayAmount = !_.isNaN(value)
        ? new CurrencyFormat(worldready, {
            currency,
            maximumFractionDigits: fractionDisplay(value),
          }).format(value / 100)
        : 'NaN'

      return {
        sku: item.sku,
        displayAmount,
      }
    })

    return formatedDenominations
  }

  customAmountField = (customAmountsWithSku, worldready) => {
    const { minAmount, maxAmount } = customAmountsWithSku.customAmount
    const formatedMinAmount = new CurrencyFormat(worldready, {
      currency: minAmount.currency_code,
      maximumFractionDigits: fractionDisplay(minAmount.value),
    }).format(minAmount.value / 100)

    const formatedMaxAmount = new CurrencyFormat(worldready, {
      currency: maxAmount.currency_code,
      maximumFractionDigits: fractionDisplay(maxAmount.value),
    }).format(maxAmount.value / 100)

    const customAmount = {
      sku: customAmountsWithSku.sku,
      minAmount: formatedMinAmount,
      maxAmount: formatedMaxAmount,
    }

    return customAmount
  }

  renderCustomRadioBtn = (customAmounts, selectedSku) => {
    return (
      <RadioBtn
        name="amount"
        id="_other"
        data-testid="other-amount"
        checked={customAmounts.sku === selectedSku}
        onChange={() => this.handleClickOther(customAmounts.sku)}
        label={
          <AmountLabel>
            <Message id="pages/product.product.other" />
          </AmountLabel>
        }
      />
    )
  }

  renderBundlePrice(displayAmounts) {
    return (
      <BundleAmount py="x1">
        <Text fontSize="1.25rem">
          <Message id="pages/product.product.bundleHeader" />
        </Text>
        <Text fontSize="1.25rem" fontWeight="700" ml="auto">
          {displayAmounts[0].displayAmount}
        </Text>
      </BundleAmount>
    )
  }

  render() {
    const {
      fixedAmounts,
      customAmountsWithSku,
      isBundle,
      sku: selectedSku,
    } = this.props
    const hasOnlyCustomAmountSku =
      _.isEmpty(fixedAmounts) && customAmountsWithSku !== null

    return (
      <WorldReadyContextConsumer>
        {(worldready) => {
          const displayAmounts =
            fixedAmounts &&
            this.getFixedDisplayAmounts(fixedAmounts, worldready)

          const customAmounts =
            customAmountsWithSku &&
            this.customAmountField(customAmountsWithSku, worldready)

          return (
            <div>
              <div id="choose-amount">
                <Container>
                  <Row>
                    {this.renderFixAmountRadioBtn(displayAmounts, selectedSku)}
                    {customAmounts &&
                      !hasOnlyCustomAmountSku &&
                      this.renderCustomRadioBtn(customAmounts, selectedSku)}
                    {isBundle && this.renderBundlePrice(displayAmounts)}
                  </Row>
                </Container>
              </div>
              {(customAmounts && customAmounts.sku === selectedSku) ||
              this.state.showCustomAmountField
                ? this.renderAmountInputField(customAmounts)
                : null}
            </div>
          )
        }}
      </WorldReadyContextConsumer>
    )
  }
}

const AmountSectionWithGraphql = compose(withApollo)(AmountSection)
export default AmountSectionWithGraphql
