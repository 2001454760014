import React from 'react'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import stripHtmlComments from 'strip-html-comments'
import { CurrencyFormat } from '@paypalcorp/worldready'
import { Button, Row } from '@paypalcorp/pp-react'

import clientData from 'utils/client-data'
import EmptyCart from './empty-cart'
import GiftCardItem from './gift-card-item'
import * as Styled from './cart-summary.styled'
import TextLinkToggle from '../../components/text-link-toggle'
import { ProductDiv } from '../shared/styled'
import DomPurify from 'dompurify'

import {
  Message,
  ContextConsumer as WorldReadyContextConsumer,
} from '@paypalcorp/worldready-react'

const country = clientData.locality.country

const isTermsConsentRequired = country === 'DE' || country === 'AT'

const getTermsConsentLink = () => {
  if (country === 'DE') {
    return 'https://www.paypal.com/de/webapps/mpp/ua/ppdg-tnc?locale.x=de_DE'
  }

  return 'https://www.paypal.com/at/webapps/mpp/ua/ppdg-tnc?locale.x=de_AT'
}

const CartSummary = ({
  storeInfo,
  cartItems = [],
  soldOutPromotionsBySku = [],
  handleBuyNow,
  handleRemoveItem,
  handleUpdateItem,
  handleEmptyCartBtnClick,
}) => {
  const renderAmountToPay = (totalAmount, currency, worldready) => {
    return (
      <Row id="total-amount">
        <Styled.Total md={8}>
          <Message id="pages/cart-summary.cartsummary.total" />
        </Styled.Total>

        <Styled.RightCol md={4} style={{ marginTop: '3px' }}>
          <Styled.TotalAmount>
            {new CurrencyFormat(worldready, { currency }).format(
              totalAmount / 100,
            )}
          </Styled.TotalAmount>
        </Styled.RightCol>
      </Row>
    )
  }

  const renderTermsInExpandMode = (item, idx) => {
    return (
      <Styled.TermsAndConditions
        key={idx}
        id="product-terms-conditions"
        dangerouslySetInnerHTML={{
          __html: DomPurify.sanitize(
            stripHtmlComments(item.productTermsAndConditions),
          ),
        }}
      />
    )
  }

  const renderTermsInCollapseMode = (item, idx) => {
    return (
      <Styled.TermsAndConditions key={idx} id="product-terms-conditions">
        <TextLinkToggle
          linkTitle={
            <Message
              id="pages/cart-summary.cartsummary.proudctTerms"
              name={item.productName}
            />
          }
          htmlTextBody={item.productTermsAndConditions}
        />
      </Styled.TermsAndConditions>
    )
  }

  const renderProductTermsAndConditions = () => {
    const uniqTerms = lodash.uniqBy(cartItems, 'productName')
    return uniqTerms.map((item, idx) => {
      if (/expand-panel-tnc/.test(item.productTermsAndConditions)) {
        return renderTermsInExpandMode(item, idx)
      }
      return renderTermsInCollapseMode(item, idx)
    })
  }

  const renderPPTermsAndConditions = () => {
    if (isTermsConsentRequired) {
      return (
        <Styled.PPDGTermsAcknowledge id="ppdg-terms-conditions-acknowledge">
          <Message
            id="pages/cart-summary.cartsummary.termsAcknowledge"
            link={(innerMessage) => (
              <a href={getTermsConsentLink()} rel="noreferrer" target="_blank">
                {innerMessage}
              </a>
            )}
          />
        </Styled.PPDGTermsAcknowledge>
      )
    }

    return (
      <div id="ppdg-terms-conditions">
        <TextLinkToggle
          linkTitle={
            <Message id="pages/cart-summary.cartsummary.ppdgTermsTitle" />
          }
          htmlTextBody={storeInfo.getStoreInfo.terms_and_conditions}
        />

        <Styled.BuyNowTerms id="product-terms">
          <Message id="pages/cart-summary.cartsummary.terms" />
        </Styled.BuyNowTerms>
      </div>
    )
  }

  const renderCart = () => {
    let totalAmountToPay = 0
    const soldOutPromotionsBySkuSet = new Set(soldOutPromotionsBySku)

    return (
      <WorldReadyContextConsumer>
        {(worldready) => {
          return (
            <Styled.Content>
              {cartItems.map((item, idx) => {
                totalAmountToPay +=
                  Number(item.amountToPay.value) * item.quantity
                return (
                  <GiftCardItem
                    key={idx}
                    item={item}
                    onRemoveItem={() => handleRemoveItem(item)}
                    onUpdateItem={handleUpdateItem}
                    isPromotionSoldOut={soldOutPromotionsBySkuSet.has(item.sku)}
                  />
                )
              })}

              {renderAmountToPay(
                totalAmountToPay,
                cartItems[0].amountToPay.currency_code,
                worldready,
              )}

              <Styled.Note id="note">
                <Message id="pages/cart-summary.cartsummary.note" />
              </Styled.Note>

              {renderProductTermsAndConditions()}

              {renderPPTermsAndConditions()}

              <div style={{ textAlign: 'center' }}>
                <Button
                  data-pagename="main:consumer:gifts:cart:"
                  data-pagename2="main:consumer:gifts:cart:summary:::"
                  data-extra={JSON.stringify({
                    curr: cartItems[0].amountToPay.currency_code,
                    amt: (totalAmountToPay / 100).toString(),
                    count: cartItems.reduce((s, item) => s + item.quantity, 0),
                  })}
                  name="BuyNow"
                  data-testid="buy-now-button"
                  onClick={handleBuyNow}
                >
                  <Message
                    id={
                      isTermsConsentRequired
                        ? 'pages/cart-summary.cartsummary.btnAcknowledgement'
                        : 'pages/cart-summary.cartsummary.btn'
                    }
                  />
                </Button>
              </div>
            </Styled.Content>
          )
        }}
      </WorldReadyContextConsumer>
    )
  }

  return (
    <div>
      <ProductDiv id="cart-summary-page">
        {lodash.isEmpty(cartItems) ? (
          <EmptyCart handleEmptyCartBtnClick={handleEmptyCartBtnClick} />
        ) : (
          <div>
            <Styled.Header>
              <Message id="pages/cart-summary.cartsummary.header" />
            </Styled.Header>

            {renderCart()}
          </div>
        )}
      </ProductDiv>
    </div>
  )
}

CartSummary.propTypes = {
  storeInfo: PropTypes.shape({
    getStoreInfo: PropTypes.shape({
      terms_and_conditions: PropTypes.string,
    }),
  }).isRequired,
  cartItems: PropTypes.array,
  soldOutPromotionsBySku: PropTypes.array,
  handleBuyNow: PropTypes.func.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  handleUpdateItem: PropTypes.func.isRequired,
  handleEmptyCartBtnClick: PropTypes.func.isRequired,
}

export default CartSummary
