import React from 'react'

import { Box } from '../box'
import { LoadingSpinner } from '@paypalcorp/pp-react'

const Loader = (props) => {
  return (
    <Box display="flex" justifyContent="center" {...props}>
      <LoadingSpinner size="xl" />
    </Box>
  )
}

export default Loader
