import React from 'react'
import styled from '@emotion/styled/macro'
import { FlexBox } from '../../components/box'
import {
  BodyText,
  CaptionText,
  Link,
  Button as PPButton,
  OffersIcon as PPOffersIcon,
} from '@paypalcorp/pp-react'

export const CalenderInputDiv = styled.div`
  padding: 1.75rem 0.6875rem 0.625rem 0.6875rem;
  color: #001435;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-overflow: ellipsis;
  box-sizing: border-box;
  width: 100%;
  border: #929496 solid 0.0625rem;
  border-radius: 0.25rem;
  background-color: #ffffff;
  margin: 0;
  outline: none;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 4rem;
`

export const DateLabel = styled.label`
  color: #545d68;
  line-height: 1.25rem;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0.5rem;
  -webkit-transition: font-size 0.2s cubic-bezier(0.2, 0, 0, 1),
    top 0.2s cubic-bezier(0.2, 0, 0, 1),
    background-color 0.2s cubic-bezier(0.2, 0, 0, 1);
  transition: font-size 0.2s cubic-bezier(0.2, 0, 0, 1),
    top 0.2s cubic-bezier(0.2, 0, 0, 1),
    background-color 0.2s cubic-bezier(0.2, 0, 0, 1);
  right: 0.75rem;
  left: 0.75rem;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
`

export const DateInput = styled.input`
  display: table-cell;
  border: none;
  outline: none;
  font-size: 18px;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`

export const CalendarIconButton = styled.button`
  border: 0;
  background-color: transparent;
  margin-right: 14;
  margin-top: -6;
  color: ${({ theme }) => theme.colors.secondary.greymidnight};
`

export const Header = styled.h1`
  font-size: 30px;
  font-weight: 300;
  color: #2c2e2f;
  text-align: center;
  padding-top: 60px;
  margin: 0 12px;
`

export const ImgDiv = styled.div`
  margin: 48px 0;
  display: block;
  text-align: center;
`

export const Img = styled.img`
  width: 200px;
  height: 126px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 2px 3px 6px 0 #9b9b9b;
`

export const BundleItem = styled(FlexBox)`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary.greylight};
`

export const BundleImg = styled.img`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 2px 3px 6px 0 #9b9b9b;
  max-width: 320px;
`

export const CardImage = styled.img`
  border-radius: 4px;
  max-width: 100px;
`

export const Description = styled(BodyText)`
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  color: #2c2e2f;
  margin-bottom: 16px;

  a {
    font-family: 'PayPalOpen-Bold';
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 24px;
  }
`

export const OfferTermsSections = styled(BodyText)`
  margin-bottom: 12px;
`

export const OffersIcon = styled(PPOffersIcon)`
  padding-right: 7px;

  & path {
    fill: ${({ theme }) => theme.colors.primary.bluemidnight};
  }
`

export const MerchantTnCSection = styled(BodyText)`
  margin-bottom: 36px;
`

export const Promotion = styled.div`
  padding-top: 24px;
`

export const Tab = styled.div`
  padding-top: 24px;
  padding-bottom: 22px;
`

export const InputContainer = styled.div`
  position: relative;
  padding-top: 0;
  margin-top: 1em;
`

export const ErrorMessageContainer = styled.div`
  color: #d20000;
  display: flex;
  align-items: center;
`

export const ErrorMessageText = styled(CaptionText)`
  color: #d20000;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
`

const TabButton = (props) => <Link as="button" {...props} />

export const MeTab = styled(TabButton)`
  width: 50%;
  height: 60px;
  font-size: 15px;
  background-color: transparent;
  position: relative;
  border: none;
  text-align: center;

  &:focus {
    outline: none;
  }
`

export const GiftTab = MeTab

export const Button = styled(PPButton)`
  width: 270px;
  height: 48px;
  margin: 48px 0 60px;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }
`

export const Content = styled.div`
  margin: 0 174px;

  @media only screen and (max-width: 768px) {
    margin: 0 12px;
  }
`

export const ClickedStyle = {
  color: '#0070ba',
  borderBottom: `3px solid #0070ba`,
}

export const DefaultStyle = {
  color: '#2c2e2f',
  borderBottom: 'solid 1px #cbd2d6',
}

export const PromoDes = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.secondary.greymidnight};
`
