import React from 'react'
import { Box } from '../box'
import { Text } from '../text'
import { Col } from '../grid'
import { SearchInput } from '../search-bar'
import { NavLink } from '../../styled/nav-link.styled'

import {
  NavSticky,
  NavContainer,
  NavRow,
  NavBox,
  NavSlider,
  SearchContainer,
} from './nav-bar.styled'

const NavBar = ({
  items = [],
  hasSearchInput = false,
  searchInputVisible = false,
  onSearch = () => {},
  CartComponent,
}) => (
  <NavSticky>
    <NavContainer>
      <NavRow>
        <Col xs={12}>
          <NavBox>
            <NavSlider>
              {items
                .filter(({ enabled = true }) => enabled)
                .map(
                  ({ name, label, to, exact, dataPagename, dataPagename2 }) => (
                    <NavLink
                      key={name}
                      to={to}
                      name={name}
                      exact={exact}
                      data-pagename={dataPagename}
                      data-pagename2={dataPagename2}
                      data-testid={`${name}-nav-link`}
                      activeClassName="active"
                    >
                      <Box
                        mr="1"
                        ml="1"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Text textStyle="subnav" textDecoration="none">
                          {label}
                        </Text>
                      </Box>
                    </NavLink>
                  ),
                )}
            </NavSlider>

            <Box display="flex" justifyContent="flex-end">
              {hasSearchInput && (
                <SearchContainer show={searchInputVisible}>
                  <SearchInput
                    id="nav"
                    inNav={true}
                    onSubmit={onSearch}
                    placeholder="Search Gifts"
                  />
                </SearchContainer>
              )}

              {CartComponent}
            </Box>
          </NavBox>
        </Col>
      </NavRow>
    </NavContainer>
  </NavSticky>
)

export default NavBar
