import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Styled from './Carousel.styled'
import { Box } from '../box'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'
import { useTheme } from '@emotion/react/macro'
import { ChevronLeftIcon, ChevronRightIcon } from '@paypalcorp/pp-react'

const Carousel = ({
  items,
  pageButtons,
  forceSnap,
  disableSnap,
  disableFaders,
  itemSpacing,
  center,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [scrollTimeout, setScrollTimeout] = useState(false)
  const [doSnap, setDoSnap] = useState(forceSnap)
  const [resizeTimeout, setResizeTimeout] = useState(false)

  const theme = useTheme()

  const curItemSpacing = theme.spaceData[itemSpacing].value
  const { margins, maxWidth } = theme.breakpointData
  const marginVals = margins.map((margin) => margin.value)

  const edgeItemMargins = [...marginVals.slice(0, 4), 0]
  const sliderMargins = [0, 0, 0, 0, marginVals[4]]

  const cbp = useCurrentBreakpoint()

  const sliderRef = useRef()
  const sliderDims = sliderRef.current
    ? sliderRef.current.getBoundingClientRect()
    : {}

  const getKeyChildren = () => {
    const children = Array.prototype.slice.call(sliderRef.current.childNodes)

    const childrenData = children.map((el, i) => ({
      el,
      ind: i,
      dims: el.getBoundingClientRect(),
    }))

    const sortMetric = (child) =>
      Math.abs(
        child.dims.x -
          sliderDims.x -
          sliderDims.width / 2 +
          child.dims.width / 2,
      )

    const middle = [...childrenData].sort(
      (a, b) => sortMetric(a) - sortMetric(b),
    )[0]

    const first = childrenData.shift()
    const last = childrenData.pop() || first

    return { first, middle, last }
  }

  const handleScroll = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout)
    const { first, middle, last } = getKeyChildren()

    const firstIsVisible = sliderRef.current.scrollLeft < first.dims.width / 3
    const lastIsVisible =
      sliderRef.current.scrollWidth -
        (sliderDims.width + sliderRef.current.scrollLeft) <
      last.dims.width / 3

    let newCurrent = middle
    let newScrollX =
      newCurrent.dims.x -
      sliderDims.x -
      sliderDims.width / 2 -
      curItemSpacing / 2 +
      newCurrent.dims.width / 2 +
      sliderRef.current.scrollLeft

    if (!firstIsVisible || !lastIsVisible) {
      if (firstIsVisible) {
        newCurrent = first
        newScrollX = 0
      }
      if (lastIsVisible) {
        newCurrent = last
        newScrollX = sliderRef.current.scrollWidth
      }
    }

    setCurrentIndex(newCurrent.ind)

    if ((doSnap || forceSnap) && !disableSnap)
      setScrollTimeout(
        setTimeout(() => {
          if (sliderRef.current.scrollTo)
            sliderRef.current.scrollTo({
              left: newScrollX,
              behavior: 'smooth',
            })
          else sliderRef.current.scrollLeft = newScrollX
          setScrollTimeout(false)
        }, 250),
      )
  }

  const handleResize = () => {
    if (resizeTimeout) clearTimeout(resizeTimeout)
    setResizeTimeout(
      setTimeout(() => {
        const carouselWidth = sliderRef.current.getBoundingClientRect().width
        const itemWidth =
          sliderRef.current.childNodes[0].getBoundingClientRect().width +
          curItemSpacing
        const snapNeeded = carouselWidth <= itemWidth * 2.1
        setDoSnap(snapNeeded)
        setResizeTimeout(false)
      }, 250),
    )
  }

  const movePage = (direction) => {
    const { middle } = getKeyChildren()
    const numToMove = Math.floor(
      sliderDims.width / sliderRef.current.childNodes[0].clientWidth,
    )
    const newCurrentIndex = Math.min(
      items.length - 1,
      Math.max(0, middle.ind + (direction === 'left' ? -numToMove : numToMove)),
    )

    const newCurrentDims =
      sliderRef.current.childNodes[newCurrentIndex].getBoundingClientRect()

    const newScrollX =
      newCurrentDims.x -
      sliderDims.x -
      sliderDims.width / 2 -
      curItemSpacing / 2 +
      newCurrentDims.width / 2 +
      sliderRef.current.scrollLeft

    if (sliderRef.current.scrollTo)
      sliderRef.current.scrollTo({
        left: newScrollX,
        behavior: 'smooth',
      })
    else sliderRef.current.scrollLeft = newScrollX
    setCurrentIndex(newCurrentIndex)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    handleScroll()
    return () => window.removeEventListener('resize', handleResize)
  }, [cbp])

  const pageButtonsNeeded =
    sliderRef.current &&
    sliderDims &&
    sliderRef.current.scrollWidth > sliderDims.width

  return (
    <Box width="100%" maxWidth={maxWidth.value} mx="auto" position="relative">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={center ? 'center' : 'flex-start'}
        mx={sliderMargins}
        overflow="visible"
      >
        <Styled.CarouselSlider
          py="2rem"
          my="-2rem"
          px={{ _: 0, lg: '1rem' }}
          mx={{ _: 0, lg: '-1rem' }}
          ref={sliderRef}
          current={currentIndex}
          onScroll={handleScroll}
          className="carousel-slider"
        >
          {!!items &&
            items.map((item, ind) => (
              <Box
                pr={
                  ind === items.length - 1
                    ? edgeItemMargins.map(
                        (im) => `${im + (cbp > 3 ? 16 : 0)}px`,
                      )
                    : itemSpacing
                }
                pl={ind === 0 ? edgeItemMargins : 0}
                key={`carousel_item_${ind}`}
                ind={ind}
                overflow="visible"
              >
                {item}
              </Box>
            ))}
        </Styled.CarouselSlider>
      </Box>
      {!disableFaders && cbp > 3 && <Styled.Faders />}
      {pageButtons && pageButtonsNeeded && (
        <Styled.Controls>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mx={['-1.125rem', '-1.125rem', '-2rem']}
          >
            <Styled.ScrollButton
              className={`carousel-prev ${currentIndex > 0 ? '' : 'hidden'}`}
              name="chevron-left"
              onClick={() => movePage('left')}
            >
              <ChevronLeftIcon size="sm" />
            </Styled.ScrollButton>

            <Styled.ScrollButton
              className={`carousel-next ${
                currentIndex < items.length - 1 ? '' : 'hidden'
              }`}
              name="chevron-right"
              onClick={() => movePage('right')}
            >
              <ChevronRightIcon size="sm" />
            </Styled.ScrollButton>
          </Box>
        </Styled.Controls>
      )}
    </Box>
  )
}

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.element),
  pageButtons: PropTypes.bool,
  forceSnap: PropTypes.bool,
  disableSnap: PropTypes.bool,
  disableFaders: PropTypes.bool,
  itemSpacing: PropTypes.string,
  center: PropTypes.bool,
}

Carousel.defaultProps = {
  itemSpacing: 'x2',
  pageButtons: true,
}

export default Carousel
