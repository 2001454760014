import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import InputFieldWithBtn from '../shared/input-field-with-btn'
import isEmailValid from '../../utils/validate-email'
import clientData from '../../utils/client-data'
import { withApollo } from '@apollo/client/react/hoc'
import CalendarInput from '../shared/calendar/calendarInput'
import { flowRight as compose } from 'lodash'
import { withTheme } from '@emotion/react/macro'
import {
  Row,
  Col,
  Alert,
  Button,
  CriticalIcon,
  CaptionText,
} from '@paypalcorp/pp-react'

import {
  MessageFormat,
  CurrencyFormat,
  DateTimeFormat,
} from '@paypalcorp/worldready'
import {
  Message,
  ContextConsumer as WorldReadyContextConsumer,
} from '@paypalcorp/worldready-react'

const Item = styled.div`
  padding-top: 12px;
`

const ItemRow = styled(Row)`
  padding-top: 12px;
`

const Img = styled.img`
  width: 100px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 2px 3px 3px 0 #9b9b9b;
`

const RightCol = styled(Col)`
  text-align: right;

  @media only screen and (max-width: 768px) {
    position: absolute;
  }
`

const Line = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 12px 0 22px 0;
  padding: 0;
  background-color: #e7ebed;
`

const ChangeDelivery = styled.div`
  height: 48px;
  width: 100%;
  display: inline-block;
  border-radius: 5px;
`

const DeliveryMethod = styled.div`
  padding-bottom: 4px;
  color: #4a4a4a;
  font-size: 15px;
`

const ChangeBtn = styled(Button)`
  font-size: 13px;
  font-weight: 500;
  color: #0070ba;
  padding-top: 0;

  :focus {
    outline: none;
  }
`

const RemoveBtn = styled(Button)`
  text-align: right;
  margin-right: 20px;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  color: #0070ba;

  :focus {
    outline: none;
  }
`

const ItemName = styled.div`
  font-size: 13px;
  color: #4a4a4a;
  padding-top: 12px;
`

const Discount = styled.div`
  font-size: 13px;
  color: #4a4a4a;
  padding-top: 6px;
  padding-bottom: 24px;
`

const ErrorMessageContainer = styled.div`
  color: #d20000;
  display: flex;
  align-items: center;
`

class GiftCardItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    onUpdateItem: PropTypes.func.isRequired,
    isPromotionSoldOut: PropTypes.bool,
  }

  state = {
    changeDelivery: false,
    showCalendarInput: false,
  }

  formatDisplayAmount = (item, worldready) => {
    const toPayValue = (item.amountToPay.value * item.quantity) / 100
    const amountToPay = new CurrencyFormat(worldready, {
      currency: item.amountToPay.currency_code,
    }).format(toPayValue)

    const totalSingleAmount = new CurrencyFormat(worldready, {
      currency: item.totalAmount.currency_code,
    }).format(item.totalAmount.value / 100)

    const totalValue = (item.totalAmount.value * item.quantity) / 100
    const totalAmount = new CurrencyFormat(worldready, {
      currency: item.totalAmount.currency_code,
    }).format(totalValue)

    return { amountToPay, totalAmount, totalSingleAmount }
  }

  renderSoldOutError = () => {
    if (!this.props.isPromotionSoldOut) return null

    return (
      <Row>
        <Col>
          <Alert type="error">
            <Message id="pages/product.product.soldOut" />
          </Alert>
        </Col>
      </Row>
    )
  }

  renderItem = (displayAmount) => {
    const { totalAmount, amountToPay, totalSingleAmount } = displayAmount
    return (
      <ItemRow id="item">
        <Col md={6} id="item">
          <Img src={this.props.item.imgUrl} alt={this.props.item.productName} />
          <ItemName id="name">
            {totalSingleAmount} {this.props.item.productName}
          </ItemName>
          {this.props.item.discounts.map((discount, idx) => {
            return (
              <Discount key={idx} id="discount">
                {discount.name}
              </Discount>
            )
          })}
        </Col>

        <RightCol md={6} style={{ marginTop: '3px' }}>
          {totalAmount === amountToPay
            ? this.renderItemAmount(totalAmount)
            : this.renderItemAmountWithDiscount(totalAmount, amountToPay)}
        </RightCol>
      </ItemRow>
    )
  }

  renderItemAmount = (totalAmount) => {
    return (
      <div>
        <Message id="pages/cart-summary.cartsummary.quantity" />
        {this.props.item.quantity}
        <span style={{ marginLeft: '15px' }}>{totalAmount}</span>
      </div>
    )
  }

  renderItemAmountWithDiscount = (totalAmount, amountToPay) => {
    return (
      <div>
        <div>
          <Message id="pages/cart-summary.cartsummary.quantity" />
          {this.props.item.quantity}
          <span style={{ marginLeft: '15px', textDecoration: 'line-through' }}>
            {totalAmount}
          </span>
        </div>
        <div style={{ color: '#d20000', paddingTop: '6px' }}>{amountToPay}</div>
      </div>
    )
  }

  // Change delivery input field
  renderChangeDelivery = () => {
    return (
      <WorldReadyContextConsumer>
        {(worldready) => (
          <div>
            <ChangeDelivery id="change-delivery-method">
              <InputFieldWithBtn
                id="delivery-method"
                style={{ flex: 1 }}
                title={new MessageFormat(worldready, {
                  id: 'pages/cart-summary.cartsummary.delivery',
                }).format()}
                inputDefaultValue={this.props.item.recipientEmail}
                handleBtnClick={(value) => this.handleUpdateEmail(value)}
                suppressErrorMsg={this.suppressErrorMsg}
                shouldApplyOnBlur={false}
              />
            </ChangeDelivery>
            {this.state.invalidEmail && (
              <ErrorMessageContainer>
                <CriticalIcon size="sm" />
                <Message id="pages/cart-summary.cartsummary.invalidEmail" />
              </ErrorMessageContainer>
            )}
          </div>
        )}
      </WorldReadyContextConsumer>
    )
  }

  handleUpdateEmail = (value) => {
    if (!isEmailValid(value)) {
      this.setState({ invalidEmail: true })
      return
    }

    this.setState((prevState) => {
      this.props.onUpdateItem(this.props.item, {
        ...this.props.item,
        recipientEmail: value,
      })

      return {
        ...prevState,
        recipientEmail: value,
        changeDelivery: false,
      }
    })
  }

  suppressErrorMsg = () => {
    this.setState({ invalidEmail: false })
  }

  renderDelivery = () => {
    if (this.props.item.isGifting) {
      return this.renderEmailWithChangeBtn()
    }
    return this.renderEmailWithText()
  }

  renderEmailWithChangeBtn = () => {
    return (
      <Row id="delivery">
        <Col md={8}>
          <DeliveryMethod>
            <Message id="pages/cart-summary.cartsummary.delivery" />
          </DeliveryMethod>
          {this.renderEmail()}
        </Col>

        <RightCol md={4}>
          <ChangeBtn tertiary onClick={this.handleChangeEmail}>
            <Message id="pages/cart-summary.cartsummary.change" />
          </ChangeBtn>
        </RightCol>
      </Row>
    )
  }
  renderEmailWithText = () => {
    return (
      <div id="delivery">
        <DeliveryMethod>
          <Message id="pages/cart-summary.cartsummary.delivery" />
        </DeliveryMethod>
        <CaptionText
          style={{
            color: this.props.theme.colors.secondary.greymidnight,
          }}
        >
          <Message id="pages/cart-summary.cartsummary.deliveryToMe" />
        </CaptionText>
      </div>
    )
  }

  renderEmail = () => {
    return (
      <CaptionText
        style={{
          color: this.props.theme.colors.secondary.greymidnight,
        }}
      >
        {this.state.recipientEmail || this.props.item.recipientEmail}
      </CaptionText>
    )
  }

  renderDate = (worldready) => {
    const selectedDay = this.state.selectedDay || this.props.item.selectedDay
    const dateFormatter = new DateTimeFormat(worldready, {
      timeZone: clientData.locality.timezone,
    })
    const formattedDate = dateFormatter.format(new Date(selectedDay))
    const today = dateFormatter.format(new Date())

    return (
      <div>
        {this.state.showCalendarInput ? (
          <div id="calendar-input" style={{ marginTop: '24px' }}>
            <CalendarInput
              handleDayClick={this.handleDayClick}
              handleOnBlurCalendar={this.handleOnBlurCalendar}
              selectedDay={formattedDate}
            />
          </div>
        ) : (
          this.renderDateDisplay(formattedDate, today)
        )}
      </div>
    )
  }

  renderDateDisplay = (formattedDate, today) => (
    <Row id="date" style={{ marginTop: '24px' }}>
      <Col md={8}>
        <div
          style={{ paddingBottom: '4px', color: '#4a4a4a', fontSize: '15px' }}
        >
          <Message id="pages/cart-summary.cartsummary.date" />
        </div>
        <CaptionText
          style={{
            color: this.props.theme.colors.secondary.greymidnight,
          }}
        >
          {formattedDate === today ? (
            <Message id="pages/cart-summary.cartsummary.defaultDate" />
          ) : (
            formattedDate
          )}
        </CaptionText>
      </Col>
      {this.props.item.isGifting && (
        <RightCol md={4}>
          <ChangeBtn tertiary onClick={this.handleChangeDate}>
            <Message id="pages/cart-summary.cartsummary.change" />
          </ChangeBtn>
        </RightCol>
      )}
    </Row>
  )

  renderRemoveBtn = () => {
    return (
      <Item id="remove-button">
        <RemoveBtn
          tertiary
          data-testid="remove-button"
          onClick={this.handleRemoveItem}
        >
          <Message id="pages/cart-summary.cartsummary.remove" />
        </RemoveBtn>
      </Item>
    )
  }

  handleOnBlurCalendar = () => {
    this.setState({ showCalendarInput: false })
  }

  handleDayClick = (date, { disabled }) => {
    if (disabled) {
      return
    }

    this.setState((prevState) => {
      this.props.onUpdateItem(this.props.item, {
        ...this.props.item,
        selectedDay: date.toString(),
      })

      return {
        ...prevState,
        showCalendarInput: false,
        selectedDay: date.toString(),
      }
    })
  }

  handleChangeEmail = () => {
    this.setState({ changeDelivery: true })
  }

  handleChangeDate = () => {
    this.setState({ showCalendarInput: true })
  }

  handleRemoveItem = () => {
    this.props.onRemoveItem()
  }

  render() {
    return (
      <WorldReadyContextConsumer>
        {(worldready) => (
          <div>
            {this.renderSoldOutError()}
            {this.renderItem(
              this.formatDisplayAmount(this.props.item, worldready),
            )}
            {this.state.changeDelivery
              ? this.renderChangeDelivery()
              : this.renderDelivery()}
            {this.renderDate(worldready)}
            {this.renderRemoveBtn()}
            <Line />
          </div>
        )}
      </WorldReadyContextConsumer>
    )
  }
}

const GiftCardItemWithApollo = compose(withApollo)(withTheme(GiftCardItem))

export default GiftCardItemWithApollo
