import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react/macro'
import { BannerGiftWrap } from '../banner-giftwrap'
import { Container, Row, Col } from '../grid'
import { ImageLazy } from '../image-lazy'

import * as Styled from './EditorialHero.styled'

const EditorialHero = ({ eyebrow, headline, banner, imgUrl, ...props }) => {
  const theme = useTheme()
  const [animateIn, setAnimateIn] = useState(false)

  const onMediaLoaded = () => {
    setTimeout(() => setAnimateIn(true), 1000)
  }

  return (
    <Styled.EditorialHero
      {...props}
      pb={{ _: '131%', sm: '46%' }}
      height="0"
      maxWidth="1164px"
      borderRadius={{ _: '0', xl: theme.borderRadiusSmall }}
      mt={{ _: '0', xl: 'x2' }}
      className={animateIn ? 'animated-in' : ''}
    >
      <Styled.BgContainer>
        <ImageLazy
          h="100%"
          w="100%"
          position="absolute"
          bgSrc={imgUrl}
          onLoaded={onMediaLoaded}
        />
      </Styled.BgContainer>
      {banner && <BannerGiftWrap src="/images/giftwrap-tr.png" />}

      <Styled.Container>
        <Container>
          <Row>
            <Col md={8}>
              <Styled.TextContainer>
                <Styled.Eyebrow
                  textStyle="eyebrow"
                  color="primary.white"
                  mb="x3"
                >
                  {eyebrow}
                </Styled.Eyebrow>
                <Styled.Headline
                  textStyle="h1"
                  textShadow="0px 4px 15px rgba(0, 0, 0, 0.25)"
                >
                  {headline}
                </Styled.Headline>
              </Styled.TextContainer>
            </Col>
          </Row>
        </Container>
      </Styled.Container>
    </Styled.EditorialHero>
  )
}

EditorialHero.propTypes = {
  imgUrl: PropTypes.string,
  eyebrow: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  banner: PropTypes.bool,
}

export default EditorialHero
