import styled from '@emotion/styled/macro'
import {
  Col,
  Button as PPButton,
  HeadingText,
  CaptionText,
} from '@paypalcorp/pp-react'

export const Header = styled(HeadingText)`
  padding-top: 36px;
  padding-bottom: 24px;
  text-align: center;
  font-size: 30px;
  font-weight: 300;
`

export const Content = styled.div`
  margin: 0 174px;

  @media only screen and (max-width: 768px) {
    margin: 0 12px;
  }
`

export const BuyNowTerms = styled(CaptionText)`
  margin-top: 36px;
  margin-bottom: 48px;
  color: ${(props) => props.theme.colors.secondary.greymidnight};

  @media only screen and (max-width: 768px) {
    margin: 36px 0 48px;
  }
`

export const Note = styled(CaptionText)`
  padding-top: 36px;
  padding-bottom: 36px;
  color: ${(props) => props.theme.colors.secondary.greymidnight};
`

export const Button = styled(PPButton)`
  width: 271px;
  height: 48px;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }
`

export const RightCol = styled(Col)`
  text-align: right;

  @media only screen and (max-width: 768px) {
    position: absolute;
  }
`

export const Total = styled(Col)`
  font-size: 18px;
  color: #4a4a4a;
`

export const TotalAmount = styled.div`
  color: #2c2e2f;
`

export const TermsAndConditions = styled(CaptionText)`
  color: ${({ theme }) => theme.colors.secondary.greymidnight};
  margin-bottom: 15px;

  a {
    color: ${({ theme }) => theme.colors.primary.bluemidnight} !important;
  }
`

export const PPDGTermsAcknowledge = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  a {
    color: ${({ theme }) => theme.colors.primary.bluemidnight} !important;
  }
`
