import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import styledIcon from '../shared/icons'
import { AlertPopupSvg, SoldOutCouponSvg } from '../shared/icons/svg-images'
import { Message } from '@paypalcorp/worldready-react'
import { Button as PPButton } from '@paypalcorp/pp-react'

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 998;
  opacity: 0.45;
`

const PopupDiv = styled.div`
  position: fixed;
  top: 159px;
  margin: 0;
  height: 366px;
  width: 100%;
  z-index: 999;
`

const PopupContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  background-image: linear-gradient(287deg, #009cde, #003087);
`

const AlertICON = styledIcon`
  & svg {
    height: 98px;
    width: 98px;
  }
`

AlertICON.defaultProps = { svg: AlertPopupSvg }

const IconContainer = styled.div`
  padding-top: 47px;
  padding-bottom: 47px;
  width: 120px;
  margin: 0 auto;
`

const ContinueButton = styled(PPButton)`
  width: 270px;
  height: 48px;
  margin-top: 48px;
  margin-bottom: 36px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }

  &:hover {
    background: white;
  }
`

const Content = styled.div`
  margin: 0 auto;
  text-align: center;

  @media only screen and (max-width: 768px) {
    margin: 0 24px;
  }
`

const StyledMessage = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0;
`

const Promotion = styled.div`
  padding-top: 24px;
`

const OfferName = styled.div`
  font-size: 15px;
  color: #ffffff;
  text-align: left;
`

const CouponICON = styledIcon`
  padding-top: 6px;
  padding-right: 12px;
  
  & svg {
    height: 24px;
    width: 24px;
  }
`

CouponICON.defaultProps = { svg: SoldOutCouponSvg }

const renderOffers = (offers) => {
  return offers.map((offer, idx) => {
    return (
      <Promotion id="sold-out-offer" key={idx}>
        <div
          style={{ display: 'flex', justifyContent: 'left', margin: '0 225px' }}
        >
          <CouponICON />
          <OfferName>{offer.name}</OfferName>
        </div>
      </Promotion>
    )
  })
}

const SoldOutOverlay = (props) => (
  <div>
    <Overlay />
    <PopupDiv>
      <PopupContainer>
        <IconContainer id="icon">
          <AlertICON />
        </IconContainer>
        <Content>
          <StyledMessage>
            <Message id="pages/product.product.soldOut" />
          </StyledMessage>
          {renderOffers(props.soldOutOffers)}
        </Content>
        <ContinueButton
          secondary
          inverse
          onClick={() => props.history.push('/')}
        >
          <Message id="pages/product.product.continueShopping" />
        </ContinueButton>
      </PopupContainer>
    </PopupDiv>
  </div>
)
SoldOutOverlay.propTypes = {
  history: PropTypes.object.isRequired,
  soldOutOffers: PropTypes.array.isRequired,
}

export default SoldOutOverlay
