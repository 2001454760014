import React from 'react'
import PropTypes from 'prop-types'
import Calendar from './index'
import styled from '@emotion/styled/macro'
import { Message } from '@paypalcorp/worldready-react'
import { CalendarIcon } from '@paypalcorp/pp-react'

const CalenderInput = styled.div`
  padding: 1.75rem 0.6875rem 0.625rem 0.6875rem;
  color: #001435;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-overflow: ellipsis;
  box-sizing: border-box;
  width: 100%;
  border: #929496 solid 0.0625rem;
  border-radius: 0.25rem;
  background-color: #ffffff;
  margin: 0;
  outline: none;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 4rem;
`

const DateLabel = styled.label`
  color: #545d68;
  line-height: 1.25rem;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0.5rem;
  -webkit-transition: font-size 0.2s cubic-bezier(0.2, 0, 0, 1),
    top 0.2s cubic-bezier(0.2, 0, 0, 1),
    background-color 0.2s cubic-bezier(0.2, 0, 0, 1);
  transition: font-size 0.2s cubic-bezier(0.2, 0, 0, 1),
    top 0.2s cubic-bezier(0.2, 0, 0, 1),
    background-color 0.2s cubic-bezier(0.2, 0, 0, 1);
  right: 0.75rem;
  left: 0.75rem;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
`

const DateInput = styled.input`
  display: table-cell;
  border: none;
  outline: none;
  font-size: 18px;
`

export const CalendarIconButton = styled.button`
  border: 0;
  background-color: transparent;
  margin-right: 14;
  margin-top: -6;
  color: ${({ theme }) => theme.colors.secondary.greymidnight};
`

class CalendarInput extends React.Component {
  static propTypes = {
    handleDayClick: PropTypes.func,
    handleOnBlurCalendar: PropTypes.func.isRequired,
    selectedDay: PropTypes.string.isRequired,
  }
  state = {
    showCalendar: true,
  }

  toggleCalendar = () => {
    this.setState((prevState) => ({
      showCalendar: !prevState.showCalendar,
    }))
  }

  render() {
    return (
      <div>
        <div style={{ position: 'relative' }}>
          <CalenderInput>
            <DateLabel htmlFor="date">
              <Message id="pages/cart-summary.cartsummary.date" />
            </DateLabel>
            <div style={{ display: 'flex', marginTop: '2px' }}>
              <DateInput
                type="text"
                id="date-input"
                readOnly={true}
                style={{ flex: '1' }}
                onClick={this.toggleCalendar}
                value={this.props.selectedDay}
              />
              <CalendarIconButton onClick={this.toggleCalendar}>
                <CalendarIcon size="sm" />
              </CalendarIconButton>
            </div>
          </CalenderInput>
        </div>

        {this.state.showCalendar ? (
          <Calendar
            handleDayClick={this.props.handleDayClick}
            handleOnBlur={this.props.handleOnBlurCalendar}
          />
        ) : null}
      </div>
    )
  }
}

export default CalendarInput
