import React, { Fragment, useContext } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import getAllDeals from '../../graphql/getAllDeals'
import { HeroHeader } from '../../components/hero-header'
import { Button } from '../../components/button'
import { ContentBlock } from '../../components/content-block'
import { Loader } from '../../components/loader'
import { Text } from '../../components/text'
import { Box } from '../../components/box'
import { useTrackImpression } from '../../utils/hooks/useTrackImpression'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'
import { handleSessionError } from '../../utils/error-tracking'
import { MessageFormat } from '@paypalcorp/worldready'
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react'
import { TitleTicker } from '../../components/title-ticker'

const media = {
  lg: 'https://paypalobjects.com/gifts/assets/holiday/holiday_deals_hero_2024_lg.jpg',
  sm: 'https://paypalobjects.com/gifts/assets/holiday/holiday_deals_hero_2024_sm.jpg',
}

const Deals = () => {
  const { data, loading, error } = useQuery(getAllDeals)
  const cbp = useCurrentBreakpoint()

  if (loading) {
    return <Loader mt={6} />
  }
  if (error) {
    handleSessionError(error)
    return <Redirect to="/error" />
  }

  const allDeals = data.getDealsInfo
  const noDeals = !allDeals

  if (noDeals)
    return (
      <Box display="flex" height="200px">
        <Text m="auto" textStyle="h4">
          {<Message id="pages/deals.deals.empty" />}
        </Text>
      </Box>
    )

  return allDeals.map((deal, i) => (
    <ContentBlock
      pt="x7"
      pb={i === allDeals.length - 1 ? `x7` : '0'}
      key={deal.name}
      header={deal.name}
      body={deal.description}
      terms={deal.terms}
      alt=""
      img={(cbp < 1 ? deal.mobile_banner_url : deal.banner_url) || deal.img_url}
      variant={i % 2 === 0 ? 'left' : 'right'}
      ctaComponent={
        <Button
          variant="primary"
          width="15rem"
          label={<Message id="pages/deals.deals.cta" />}
          href={deal.url_key}
          name="SeeDeal"
          data-pagename="main:consumer:gifts:deals:"
          data-pagename2={`main:consumer:gifts:deals:${deal.url_key}:::`}
        />
      }
    />
  ))
}

const DealsPage = () => {
  useTrackImpression(
    'main:consumer:gifts:deals:',
    'main:consumer:gifts:deals::::',
  )

  const worldready = useContext(WorldReadyContext)
  const suggestions = new MessageFormat(worldready, {
    id: 'pages/suggestions.suggestions.all',
  }).format()
  const headline = new MessageFormat(worldready, {
    id: 'pages/deals.deals.hero.title',
  }).format()

  return (
    <Fragment>
      <HeroHeader
        headerStyle="search"
        headlineComponent={<TitleTicker textItems={[headline]} delay={7500} />}
        searchSuggestions={suggestions ? suggestions.split(',') : []}
        mediaSrc={media}
        isHeroExpanded
      />
      <Deals />
    </Fragment>
  )
}

export default withRouter(DealsPage)
