import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { SuccessSvg } from '../icons/svg-images'
import styledIcon from '../icons'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { flowRight as compose } from 'lodash'
import { Message } from '@paypalcorp/worldready-react'
import { Button as PPButton } from '@paypalcorp/pp-react'

const SuccessICON = styledIcon`
  & svg {
    height: 30px;
    width: 30px;
  }
`

SuccessICON.defaultProps = { svg: SuccessSvg }

const Button = styled(PPButton)`
  min-width: 70px;
  width: 120px;
  height: 100%;
  border-radius: 0 5px 5px 0;
  background-color: #0070ba;
  border: solid 3px #0070ba;
  color: #ffffff;
  font-weight: 500;
  padding: 0;
`

const Input = styled.input`
  border: none;
  outline: none;
  font-size: 15px;
  width: 100%;
  color: #687173;
  line-height: 1;
  margin-top: 18px;
`

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
`

const InputDiv = styled.div`
  position: relative;
  flex: 1;
  padding-left: 12px;
  border-radius: 5px 0 0 5px;
`

class InputWithBtn extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    inputDefaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    handleBtnClick: PropTypes.func.isRequired,
    customAmount: PropTypes.bool,
    client: PropTypes.object,
    customAmountsWithSku: PropTypes.object,
    suppressErrorMsg: PropTypes.func.isRequired,
    onEnter: PropTypes.func,
    amountError: PropTypes.bool,
    shouldApplyOnBlur: PropTypes.bool,
  }

  state = {
    value: this.props.inputDefaultValue,
  }

  updateInputValue = (e) => {
    this.setState({ value: e.target.value })
    this.props.suppressErrorMsg() // todo: update this method to a more meaningful name
    if (this.props.onEnter) this.props.onEnter()

    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          checkedCustomAmount @client
        }
      `,
      data: { checkedCustomAmount: false },
    })

    if (this.props.customAmount) {
      // once user edit input, reset custom amount, hide the check mark
      this.props.client.writeQuery({
        query: gql`
          query getCachedData {
            customAmount @client
          }
        `,
        data: {
          customAmount: {
            currency_code:
              this.props.customAmountsWithSku.customAmount.minAmount
                .currency_code,
            value: 0,
          },
        },
      })
    }
  }

  handleClick = (value) => {
    if (this.props.customAmount) {
      const isValid = this.validateAmount(value)
      if (!isValid) {
        // clear input if it's invalid so that user can see the placeholder for min & max amount range
        this.setState({ value: '' })
      }
      // clear error msg
      this.props.suppressErrorMsg()
      if (this.props.onEnter) this.props.onEnter()

      this.props.client.writeQuery({
        query: gql`
          query getCachedData {
            checkedCustomAmount @client
          }
        `,
        data: { checkedCustomAmount: true },
      })

      this.props.handleBtnClick(isValid)
    } else {
      this.props.handleBtnClick(value)
    }
  }

  validateAmount = (value) => {
    const {
      customAmountsWithSku: { customAmount },
    } = this.props

    const amountInCent = Number(value) * 100

    if (
      amountInCent >= customAmount.minAmount.value &&
      amountInCent <= customAmount.maxAmount.value &&
      (amountInCent - customAmount.minAmount.value) %
        customAmount.stepPriceAmount ===
        0
    ) {
      const amount = {
        currency_code: customAmount.minAmount.currency_code,
        value: amountInCent,
      }

      // store valid custom input in cache
      this.props.client.writeQuery({
        query: gql`
          query getCachedData {
            customAmount @client
          }
        `,
        data: {
          customAmount: {
            currency_code: amount.currency_code,
            value: amount.value,
          },
        },
      })

      return true
    }
    return false
  }
  renderCheckMark = () => {
    return (
      <span
        id="icon"
        style={{
          position: 'absolute',
          right: '120px',
          paddingTop: '8px',
          paddingRight: '10px',
        }}
      >
        <SuccessICON />
      </span>
    )
  }

  render() {
    let renderCheckmark
    if (this.props.customAmount) {
      const cachedData = this.props.client.readQuery({
        query: gql`
          query getCachedData {
            customAmount @client {
              currency_code
              value
            }
          }
        `,
      })

      if (cachedData.customAmount.value > 0) {
        renderCheckmark = true
      }
    }

    const Label = styled.label`
      font-size: 12px;
      color: ${(props) => (props.amountError ? '#d20000' : '#0070ba')}
      margin-bottom: 0;
      height: 15px;
      line-height: 1;
      position: absolute;
      top: 6px;
    `

    return (
      <InputContainer id={this.props.id}>
        <InputDiv
          id="input-field"
          style={{
            borderLeft: this.props.amountError
              ? 'solid 2px #d20000'
              : 'solid 2px #0070ba',
            borderTop: this.props.amountError
              ? 'solid 2px #d20000'
              : 'solid 2px #0070ba',
            borderBottom: this.props.amountError
              ? 'solid 2px #d20000'
              : 'solid 2px #0070ba',
          }}
        >
          <Label htmlFor={`input_${this.props.id}`}>{this.props.title}</Label>
          <Input
            type="text"
            id={`input_${this.props.id}`}
            name={`input_${this.props.id}`}
            label={this.props.placeholder}
            aria-label={this.props.placeholder}
            placeholder={this.props.placeholder}
            value={this.state.value}
            onChange={this.updateInputValue}
            onBlur={() =>
              this.props.shouldApplyOnBlur && this.handleClick(this.state.value)
            }
          />
        </InputDiv>
        {renderCheckmark ? this.renderCheckMark() : null}

        <div>
          <Button tertiary onClick={() => this.handleClick(this.state.value)}>
            <Message id="pages/product.product.applyBtn" />
          </Button>
        </div>
      </InputContainer>
    )
  }
}

const InputWithBtnGraphql = compose(withApollo)(InputWithBtn)

export default InputWithBtnGraphql
