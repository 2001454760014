import styled from '@emotion/styled/macro'
import {
  CaptionText,
  Link,
  ChevronDownIcon as PPChevronDownIcon,
  ChevronUpIcon as PPChevronUpIcon,
} from '@paypalcorp/pp-react'

export const LinkTitle = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.bluemidnight} !important;
  padding-bottom: 12px;
  font-size: 15px;
  cursor: pointer;
`

export const TextBody = styled(CaptionText)`
  padding-top: 12px;
  color: ${({ theme }) => theme.colors.secondary.greymidnight};
  font-size: 13px;
`

export const ChevronDownIcon = styled(PPChevronDownIcon)`
  position: relative;
  top: 0.2rem;
  left: 0.2rem;
`

export const ChevronUpIcon = styled(PPChevronUpIcon)`
  position: relative;
  top: 0.3rem;
  left: 0.2rem;
`
