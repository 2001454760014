import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { ProductDiv } from '../shared/styled'
import { HeadingText, Button as PPButton } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'

const Header = styled(HeadingText)`
  padding-top: 36px;
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  color: #4a4a4a;
`

const Button = styled(PPButton)`
  width: 270px;
  height: 48px;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }
`

const Content = styled.div`
  margin: 0 174px;

  @media only screen and (max-width: 768px) {
    margin: 0 12px;
  }
`

const StyledMessage = styled.div`
  margin: 35px 0 48px 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: 0;
  text-align: center;
  color: #4a4a4a;
`

const EmptyCart = (props) => (
  <ProductDiv id="cart-summary-page" style={{ height: '669px' }}>
    <Header>
      <Message id="pages/cart-summary.cartsummary.emptyCartHeader" />
    </Header>
    <Content>
      <StyledMessage>
        <Message id="pages/cart-summary.cartsummary.emptyCartContent" />
      </StyledMessage>
      <div style={{ textAlign: 'center' }}>
        <Button onClick={props.handleEmptyCartBtnClick}>
          <Message id="pages/cart-summary.cartsummary.emptyCartContinueShopping" />
        </Button>
      </div>
    </Content>
  </ProductDiv>
)

EmptyCart.propTypes = {
  handleEmptyCartBtnClick: PropTypes.func,
}
export default EmptyCart
