import React from 'react'
import { Box } from '../box'
import { Text } from '../text'
import PropTypes from 'prop-types'
import { NavLink } from '../../styled/nav-link.styled'

import { CartContainer, CartBox, CartIcon } from './cart.styled'

const Cart = ({ label, isActive, ...rest }) => (
  <NavLink
    to="/cartsummary"
    name="Cart Summary"
    data-pagename="main:consumer:gifts:home:"
    data-pagename2="main:consumer:gifts:home::::"
  >
    <CartContainer
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pl="1"
    >
      <Box>
        <CartBox {...rest}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Text
              color={isActive ? 'primary.black' : 'secondary.greymedium'}
              textStyle="subnav"
              display={{ _: 'none', sm: 'block' }}
              mr="1"
            >
              Cart
            </Text>
          </Box>

          <Box
            color={isActive ? 'primary.black' : 'secondary.greymedium'}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <CartIcon size="md" data-testid="cart-icon" label={label} />
          </Box>
        </CartBox>
      </Box>
    </CartContainer>
  </NavLink>
)

Cart.propTypes = {
  label: PropTypes.number,
  isActive: PropTypes.bool,
}

Cart.defaultProps = {
  label: 0,
}

export default Cart
